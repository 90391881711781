/* eslint-disable max-lines */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { ProductListQuery as SourceQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query */
export class ProductListQuery extends SourceQuery {
    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        const {
            requireInfo = false,
            args: {
                filter: {
                    categoryIds = false
                } = {}
            } = {}
        } = options;

        if (!categoryIds || requireInfo) {
            return this._getProductsField();
        }

        return [
            this._getProductsField(),
            this._getPromoTilesField(categoryIds)
        ];
    }

    getPlpSliderImgUrls(options) {
        const argumentMap = this._getArgumentsMap();
        const { args } = options;
        const queryArguments = Object.entries(args).reduce((acc, [key, arg]) => {
            if (!arg) {
                return acc;
            }
            const { type, handler = (option) => option } = argumentMap[key];

            return [...acc, [key, type, handler(arg)]];
        }, []);

        const products = new Field('products')
            .addFieldList([
                new Field('items')
                    .addFieldList([
                        new Fragment('ConfigurableProduct')
                            .addFieldList([
                                new Field('variants')
                                    .addFieldList([
                                        new Field('product')
                                            .addFieldList([
                                                'color',
                                                'stock_status',
                                                this._getMediaGalleryField()
                                            ])
                                    ])
                            ])
                    ])
            ]);

        queryArguments.forEach((arg) => products.addArgument(...arg));

        return products;
    }

    getAvailableProductSizesByColorQuery(configurableProductId, selectedColorId) {
        return new Field('getAvailableProductSizesByColor')
            .addArgument('configurableProductId', 'Int!', configurableProductId)
            .addArgument('selectedColorId', 'Int!', selectedColorId)
            .addFieldList([
                'color_attribute_id',
                'size_attribute_id',
                'style_number',
                new Field('size_options')
                    .addFieldList([
                        'label',
                        'option'
                    ])
            ]);
    }

    _getPromoTilesField(categoryIds) {
        return new Field('category')
            .addArgument('id', 'Int!', categoryIds)
            .addField(
                new Field('content_blocks').addField(new Field('promo_tile'))
            );
    }

    _getProductsField() {
        const products = new Field('products')
            .addFieldList(this._getProductFields());

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        const {
            minMaxRequested,
            args: {
                filter: {
                    categoryIds = false
                } = {}
            } = {}
        } = this.options;

        if (!categoryIds) {
            products.addField(new Field('content_blocks').addField('content_html'));
        }

        if (minMaxRequested) {
            products.addArgument('minMaxRequested', 'Boolean', true);
            products.addFieldList(['min_price', 'max_price']);
        }

        return products;
    }

    _getItemsField() {
        const { isSingleProduct } = this.options;

        const items = new Field('items');

        if (isSingleProduct) {
            items.addFieldList(this._getProductInterfaceFields()).addField(this._getDownloadableProductFields());
        } else {
            items.addFieldList(this._getProductListFields());
        }

        return items;
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        const fields = [
            'id',
            'is_sliced',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'subtotal',
            this._getPriceRangeField(),
            this._getProductThumbnailField(),
            this._getShortDescriptionField(),
            'special_from_date',
            'special_to_date',
            this._getTierPricesField(),
            'label_text',
            'label_color_background',
            'label_color_foreground',
            'swatch_image_url',
            'style_url',
            'size_spec_table_imp',
            'size_spec_table',
            'material_composition',
            'upc',
            'ean',
            'season',
            'model_height',
            'model_sample_size',
            'color',
            'color_description',
            'product_division',
            'line_name',
            'gender',
            'age_group',
            'dept_code',
            'sport_code',
            'subcat_id',
            'collection',
            'technology',
            'style_number',
            'primary_category_pim_id',
            'sale_flag',
            'disable_sale_percentage_label'
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    // Assuming the below is not needed. Restore if needed.
                    // this._getBundleProductFragment()
                );
            }
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getCustomizableProductFragment()
            );

            if (isVariant) {
                fields.push('salable_qty', 'promo_excluded_flag');
            } else {
                fields.push(
                    this._getCategoriesField(),
                    // Assuming the below is not needed. Restore if needed.
                    // this._getVirtualProductFragment(),
                    // this._getCustomizableProductFragment()
                );
            }
        } else if (isForLinkedProducts) {
            fields.push('salable_qty');
        }

        return fields;
    }

    _getCartProductField() {
        return new Field('product')
            .addFieldList([
                'id',
                'sku',
                'stock_status',
                'salable_qty',
                'style_number',
                this._getStockItemField(),
                this._getProductThumbnailField(),
                this._getPriceRangeField(),
                this._getAttributesField(true, true)
            ]);
    }

    _getProductListFields(isWishlist = false) {
        const fields = [
            'id',
            'is_sliced',
            'sku',
            'name',
            'type_id',
            'url',
            'subtotal',
            this._getProductThumbnailField(),
            'label_text',
            'label_color_background',
            'label_color_foreground',
            'upc',
            'ean',
            'season',
            'color',
            'color_description',
            'product_division',
            'line_name',
            'gender',
            'age_group',
            'dept_code',
            'sport_code',
            'subcat_id',
            'collection',
            'technology',
            'style_number',
            'primary_category_pim_id',
            'sale_flag',
            'product_badge',
            'disable_sale_percentage_label'
        ];

        fields.push(
            new Fragment('ConfigurableProduct')
                .addFieldList([
                    isWishlist ? this._getCartConfigurableProductFragment() : null,
                    new Field('variants')
                        .addFieldList([
                            new Field('product')
                                .addFieldList([
                                    'sku',
                                    'stock_status',
                                    'style_number',
                                    this._getProductThumbnailField(),
                                    this._getPriceRangeField(),
                                    'color',
                                    'size',
                                    'swatch_image_url',
                                    'style_url',
                                    'style_number',
                                    isWishlist ? this._getAttributesField(true, false) : null
                                ])
                        ])
                ])
        );

        return fields;
    }

    _getPriceRangeFields() {
        return [
            this._getMinimalPriceField()
        ];
    }

    _getMinimalPriceFields() {
        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getRegularPriceField()
        ];
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo } = this.options;

        // do not request total count for PDP
        if (notRequireInfo) {
            if (isSingleProduct) {
                return [
                    this._getItemsField()
                ];
            }

            return [
                this._getItemsField()
            ];
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }
}

export default new ProductListQuery();
